// src/components/Signup.tsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe('your-publishable-key-here');

const Signup: React.FC = () => {
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const [formData, setFormData] = useState({
    broType: '',
    firstName: '',
    lastName: '',
    email: '',
    birthdate: '',
    displayName: '',
    photo: null,
  });

  const [cardError, setCardError] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const handlePhotoUpload = (files: FileList | null) => {
    if (files && files.length > 0) {
      const selectedFile = files[0];
      setFormData({ ...formData, photo: selectedFile });
    }
  };

  const handleChange = (name: string, value: any) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (cardElement) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: `${formData.firstName} ${formData.lastName}`,
          email: formData.email,
        },
      });

      if (error) {
        setCardError(error.message || 'An error occurred');
        console.error(error);
      } else {
        console.log('Payment method created:', paymentMethod);
        // Further process the payment method or create a subscription
      }
    }
  };

  return (
    <div className="bg-gray-100">
      {/* Hero section */}
        <div className="bg-blue-500 text-white py-16 sm:py-20 lg:py-24 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <h2 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold tracking-tight mb-4 sm:mb-6">
                <span className="block">Sign Up for BroClub.org</span>
                <span className="block">Join the Brotherhood!</span>
                </h2>
                <p className="text-lg sm:text-xl">Be one of the first 10,000 Bros to sign up and secure your OG "Bro G" status!</p>
            </div>
        </div>

      {/* Main content */}
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="max-w-lg mx-auto">
          {/* Signup form */}
          <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            {/* Bro Card Details */}
            <div className="mb-6">
              <h3 className="text-lg font-semibold mb-4">Bro Card Details</h3>
              <div className='mb-4'>
                <h3 className="text-lg font-semibold mb-4">Bro Type Selection</h3>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="broType">
                    Select Your Bro Type
                </label>
                <select
                    id="broType"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={formData.broType}
                    onChange={(e) => handleChange('broType', e.target.value)}
                    required
                >
                    <option value="">Select bro type</option>
                    <option value="Beach Bro">Beach Bro</option>
                    <option value="City Bro">City Bro</option>
                    <option value="Mountain Bro">Mountain Bro</option>
                    <option value="Gym Bro">Gym Bro</option>
                    <option value="Tech Bro">Tech Bro</option>
                    <option value="Foodie Bro">Foodie Bro</option>
                    <option value="Travel Bro">Travel Bro</option>
                    <option value="Gamer Bro">Gamer Bro</option>
                    <option value="Party Bro">Party Bro</option>
                    <option value="Music Bro">Music Bro</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="displayName">
                  Display Name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="displayName"
                  type="text"
                  placeholder="Display Name"
                  value={formData.displayName}
                  onChange={(e) => handleChange('displayName', e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="birthdate">
                  Birthdate
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="birthdate"
                  type="date"
                  placeholder="Birthdate"
                  value={formData.birthdate}
                  onChange={(e) => handleChange('birthdate', e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="photo">
                  Official Photo
                </label>
                <input
                  type="file"
                  id="photo"
                  accept="image/*"
                  onChange={(e) => handlePhotoUpload(e.target.files)}
                  className="hidden"
                />
                <label
                  htmlFor="photo"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer inline-block"
                >
                  {formData.photo ? 'Change Photo' : 'Upload Photo'}
                </label>
                {formData.photo && (
                  <div className="mt-2">
                    <img src={URL.createObjectURL(formData.photo)} alt="Selected" className="max-w-xs h-auto" />
                  </div>
                )}
              </div>
            </div>
            {/* Account Info */}
            <div className="mb-6">
              <h3 className="text-lg font-semibold mb-4">Account Info</h3>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="firstName">
                  First Name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="firstName"
                  type="text"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={(e) => handleChange('firstName', e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lastName">
                  Last Name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="lastName"
                  type="text"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={(e) => handleChange('lastName', e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email Address
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={(e) => handleChange('email', e.target.value)}
                  required
                />
              </div>
              <div className="mb-4 text-xs"><p>You will receive an email for account access.</p></div>
            </div>
            {/* Payment Details */}
            <div className="mb-6">
              <h3 className="text-lg font-semibold mb-4">Payment Details</h3>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cardNumber">
                  Credit Card Details
                </label>
                <CardElement
                  options={{hidePostalCode: true}}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="cardNumber"
                />
                {cardError && <p className="text-red-500 text-xs italic mt-2">{cardError}</p>}
              </div>
            </div>
            <div className="mt-6">
                <label className="inline-flex items-center">
                    <input
                    type="checkbox"
                    className="form-checkbox"
                    checked={agreedToTerms}
                    onChange={(e) => setAgreedToTerms(e.target.checked)}
                    required
                    />
                    <span className="ml-2 text-gray-700">I agree to the <a href="#" className="text-blue-500 underline">terms of service</a> and <a href="#" className="text-blue-500 underline">conditions</a></span>
                </label>
            </div>
            {/* Submit button */}
            <div className="mt-6">
                <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    disabled={!agreedToTerms} // Disable the button if terms are not agreed
                >
                    Sign Up
                </button>
                <Link
                    to="/"
                    className="ml-4 inline-block font-semibold text-sm text-blue-500 hover:text-blue-700"
                >
                    Cancel
                </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const SignupWrapper: React.FC = () => (
  <Elements stripe={stripePromise}>
    <Signup />
  </Elements>
);

export default SignupWrapper;
