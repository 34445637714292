import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Goals from './Goals';
import JoinUs from './JoinUs';

const Homepage: React.FC = () => {
  const [memberCount, setMemberCount] = useState<number>(0);

  useEffect(() => {
    // Simulate fetching member count from an API
    // Replace this with your actual API call
    const fetchMemberCount = async () => {
      // Example: const response = await fetch('api/memberCount');
      // Example: const data = await response.json();
      // Example: setMemberCount(data.memberCount);
      
      // Simulated member count
      setMemberCount(1500);
    };

    fetchMemberCount();
  }, []);

  return (
    <div className="bg-gray-100">
      {/* Hero section */}
      <div className="bg-blue-500 text-white py-16 sm:py-20 lg:py-24 px-4 sm:px-6 lg:px-8 relative">
        <div className="max-w-7xl mx-auto">
          <div className="lg:flex lg:items-center lg:justify-between">
            <div className="lg:w-1/2">
              <h2 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold tracking-tight mb-4 sm:mb-6">
                <span className="block">Welcome to TheBroClub.org</span>
                <span className="block">Join the Brotherhood!</span>
              </h2>
              <p className="text-lg sm:text-xl">
                Become an official member of TheBroClub!
              </p>
              <div className="mt-8">
                <Link
                  to="/signup"
                  className="inline-block bg-white border border-transparent rounded-md py-3 px-6 font-medium text-blue-500 hover:bg-blue-50"
                >
                  Sign Up
                </Link>
              </div>
            </div>
            <div className="mt-10 lg:mt-0 lg:flex lg:flex-shrink-0 lg:w-1/2">
              <div className="mx-auto h-48 w-48 sm:h-64 sm:w-64 lg:h-72 lg:w-72 bg-gray-300 rounded-full flex items-center justify-center pt-8 md:pt-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 64 64"
                  className="h-full w-full"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <path
                    fill="#a0a0a0"
                    d="M32 4c5.52 0 10 4.48 10 10s-4.48 10-10 10-10-4.48-10-10 4.48-10 10-10zm0 22c7.732 0 14 6.268 14 14v2h-28v-2c0-7.732 6.268-14 14-14z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 left-0 right-0 text-white bg-blue-700 py-2 text-center">
            {/* <p>Current Member Count: {memberCount}</p> */}
          </div>
        </div>
      </div>
      {/* Main content */}
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-2 lg:px-8">
        <JoinUs />
        {/* <Goals /> */}
      </div>
    </div>
  );
};

export default Homepage;
