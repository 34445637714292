import React from 'react';

const Goals = () => {
  return (
    <div className="bg-gray-100">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-2 lg:px-8">
        <div className="max-w-lg mx-auto">
          <h2 className="text-3xl font-extrabold tracking-tight text-center text-gray-900 sm:text-4xl">
            Top 5 Goals for TheBroClub Dominance in the USA
          </h2>
          <div className="mt-8">
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
              <ul className="divide-y divide-gray-200">
                <li>
                  <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg font-semibold text-gray-900">Goal 1: Expand Membership Nationwide</h3>
                    <p className="mt-2 text-sm text-gray-500">Reach bros in every state to build a 100k+ strong nationwide community.</p>
                  </div>
                </li>
                <li>
                  <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg font-semibold text-gray-900">Goal 2: Secure Exclusive BroClub Discounts</h3>
                    <p className="mt-2 text-sm text-gray-500">Negotiate discounts on bro gear, events, and services to provide valuable benefits to bros.</p>
                  </div>
                </li>
                <li>
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-lg font-semibold text-gray-900">Goal 3: Establish a Bro Scholarship</h3>
                        <p className="mt-2 text-sm text-gray-500">Create scholarships to support bros pursuing higher education, empowering them to crush life further!</p>
                    </div>
                </li>
                <li>
                  <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg font-semibold text-gray-900">Goal 4: Promote Bro Culture</h3>
                    <p className="mt-2 text-sm text-gray-500">Celebrate and promote bro culture, fostering camaraderie, loyalty, and friendship among bros.</p>
                  </div>
                </li>
                <li>
                  <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg font-semibold text-gray-900">Goal 5: Host Epic Bro Events</h3>
                    <p className="mt-2 text-sm text-gray-500">Organize legendary parties and adventures to unite bros and create unforgettable memories.</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Goals;
