import React from 'react';

interface BroCardProps {
  photo: string;
  displayName: string;
  broType: string;
  birthdate: string;
}

const BroCard: React.FC<BroCardProps> = ({ photo, displayName, broType, birthdate }) => {
  return (
    <div className="relative max-w-xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden lg:max-w-4xl">
      <div className="bg-blue-500 p-4 flex justify-between items-center">
        <h2 className="text-white text-xl font-bold">Official Bro Card</h2>
        <img src="/path/to/logo.png" alt="BroClub Logo" className="w-12 h-12" />
      </div>
      <div className="p-6">
        <div className="flex items-center">
          <img
            src={photo}
            alt="Bro Photo"
            className="w-24 h-24 rounded-full border-2 border-blue-500 mr-4"
          />
          <div>
            <h3 className="text-lg font-bold text-gray-700">{displayName}</h3>
            <p className="text-gray-600">{broType}</p>
            <p className="text-gray-600">Born: {birthdate}</p>
          </div>
        </div>
      </div>
      <div className="bg-blue-500 p-4 text-center text-white">
        <p className="font-semibold">BroClub.org</p>
        <p className="text-sm">Connect. Chill. Enjoy.</p>
      </div>
      <div className="absolute top-0 right-0 h-full w-1/4 bg-blue-500 bg-opacity-20"></div>
      <div className="absolute top-0 right-0 h-full w-1/4 border-l-8 border-blue-500 border-solid flex justify-center items-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="gold" className="h-16 w-16">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 2l2.928 5.333L22 9.75l-4 4.125.944 5.896L12 18.25l-4.944 2.521.944-5.896-4-4.125 6.072-2.417L12 2z" />
        </svg>

      </div>
    </div>
  );
};

export default BroCard;
