import React from 'react';
import Card from './Card';
import { Link } from 'react-router-dom';
import Badges from './Badges';

const handleLogout = () => {
    // Perform logout functionality (e.g., clear session, redirect to login page)
    //onLogout();
};

const Dashboard = () => {
  return (
    <div>
        {/* Header */}
        <header className="bg-blue-500 text-white py-6">
            <div className="container mx-auto flex justify-between items-center">
                <h1 className="text-2xl font-bold">Welcome to the Dashboard, !</h1>
                <Link to="/" className="text-sm font-semibold hover:underline" onClick={handleLogout}>
                    Logout
                </Link>
            </div>
        </header>

        <div className="container mx-auto mt-10">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {/* User Info */}
                <div className="bg-white shadow-md rounded-md p-6">
                    <h2 className="text-2xl font-bold mb-4">Welcome,</h2>
                    <p className="text-gray-700">Email: </p>
                    <p className="text-gray-700">Account Type: </p>
                    {/* Add more user information as needed */}
                </div>

                {/* BroCard */}
                <div className="bg-white shadow-md rounded-md p-6">
                    <h2 className="text-2xl font-bold mb-4">Your Official Bro Card</h2>
                    <Card photo={''} displayName={''} broType={''} birthdate={''}  />
                </div>
            </div>

        </div>
        <div className="container mx-auto mt-10">
            <div className="grid grid-cols-1 gap-8">
                <div className="bg-white shadow-md rounded-md p-6">
                    <h2 className="text-2xl font-bold mb-4">Badges</h2>
                    <Badges/>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Dashboard;
