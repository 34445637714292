

import React from 'react';

const JoinUs = () => {
  return (
    <div className="container mx-auto py-12 px-4">
        <p className="mt-6 text-xl text-gray-500">
        Let's raise the bro-bar and set a new standard for awesomeness!
        </p>
        <div className="mt-8 text-left">
        <p className="mb-4"><span className="emoji">🌍</span> <strong>Just a buch of Bros Nationwide:</strong> TheBroClub.org is where
            bros from every walk of life come together. Whether you're a beach bro from Cali, a city bro from NYC,
            or a mountain bro from the Rockies, get the official bro card here. Trust me bro, we are going to take this thing to the next level.</p>
        <p className="mb-4"><span className="emoji">💪</span> <strong>Exclusive Benefits for Bros:</strong> Membership has its
            perks, bro! Well, at least we intend to get it going, bro. We are tring to build out some killer deals and access to exclusive events, discounts on bro gear, and insider info on all the coolest bro
            happenings.</p>
        <p className="mb-4"><span className="emoji">🤙</span> <strong>Epic Bro Adventures Await:</strong> Legendary parties, conferences for bros, and whatever we vote on doing. The ultimate bro adventures, must ensue. Join us and let's make some classic
            bro memories that'll go down in history!</p>
        <p><span className="emoji">🤝</span> <strong>Brotherhood, Not Just a Word:</strong> Here at TheBroClub.org, we're more
            than just a community - we're a brotherhood. Lift each other up, have each other's backs, and together, we'll
            conquer the world, one bro at a time.</p>
        </div>
        <p className="my-8">Ready to dive into the awesomeness? Claim your Bro Card and join the ultimate bro movement! Let's build something great bro.</p>
    </div>
  );
};

export default JoinUs;
