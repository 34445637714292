import React from 'react';

const Badges: React.FC = () => {
  return (
    <div className="flex justify-around">
      <div className="badge">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="100" height="100">
          <circle cx="256" cy="256" r="224" fill="#FFD700"/>
          <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="200" font-weight="bold" fill="white">10K</text>
        </svg>
        <p>First 10k</p>
      </div>
      <div className="badge">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="100" height="100">
          <circle cx="256" cy="256" r="224" fill="#C0C0C0"/>
          <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="200" font-weight="bold" fill="white">25K</text>
        </svg>
        <p>First 25k</p>
      </div>
      <div className="badge">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="100" height="100">
          <circle cx="256" cy="256" r="224" fill="#CD7F32"/>
          <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="200" font-weight="bold" fill="white">50K</text>
        </svg>
        <p>First 50k</p>
      </div>
      <div className="badge">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="100" height="100">
          <circle cx="256" cy="256" r="224" fill="#FFD700"/>
          <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="200" font-weight="bold" fill="white">100K</text>
        </svg>
        <p>First 100k</p>
      </div>
    </div>
  );
};

export default Badges;
